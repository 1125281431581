import AlarmLogic from "./AlarmLogic";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Alert,
  CircularProgress,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAuthorizer } from "../../util/authorizer";
import { useActiveAlarms, useResolvedAlarms } from "../useAlarmLogs";

export default function AlarmView(props) {
  const {
    Item,
    expanded,
    expanded2,
    handleChange,
    openGraph,
    setExpanded2,
    getMasterSlaveView,
    deleteLogs,
  } = AlarmLogic(props);

  const {
    activeAlarms,
    error: activeAlarmsError,
    isLoading: activeAlarmsLoading,
  } = useActiveAlarms();

  const {
    resolvedAlarms,
    error: resolvedAlarmsError,
    isLoading: resolvedAlarmsLoading,
    loadMoreResolvedAlarmLogs,
    hasMoreResolvedLogs,
    isLoadingMore,
  } = useResolvedAlarms();

  const { isAdministrator } = useAuthorizer();
  return (
    <div>
      <Item>
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded}
          onChange={handleChange()}
        >
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Active Errors</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {activeAlarmsLoading ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <CircularProgress size={24} />
              </div>
            ) : activeAlarmsError ? (
              <Alert severity="error">
                Failed to load active alarms. Please try again later.
              </Alert>
            ) : Object.keys(activeAlarms).length === 0 ? (
              <Alert severity="info">No active alarms</Alert>
            ) : (
              Object.keys(activeAlarms).map((key: any, index: any) => (
                <Alert
                  severity={
                    activeAlarms[key].Type === "Warning" ? "warning" : "error"
                  }
                  style={{
                    borderRadius: 0,
                    width: "100%",
                  }}
                  action={
                    isAdministrator && (
                      <IconButton
                        onClick={() => {
                          deleteLogs(activeAlarms[key].id, "active");
                        }}
                        style={{ marginLeft: "auto" }}
                        size="small"
                      >
                        <Tooltip title={"Delete Log Entry"} placement="top">
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    )
                  }
                  key={key}
                >
                  <span
                    style={{
                      marginRight: 10,
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() =>
                      openGraph(activeAlarms[key].CreatedTimestamp)
                    }
                  >
                    {new Date(
                      activeAlarms[key].CreatedTimestamp
                    ).toLocaleString()}
                  </span>
                  {getMasterSlaveView(activeAlarms[key].NodeID)}
                  <span>{activeAlarms[key].Name}</span>
                </Alert>
              ))
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: "none" }}
          expanded={expanded2}
          onChange={() => setExpanded2(!expanded2)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Resolved Errors</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {resolvedAlarmsLoading ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <CircularProgress size={24} />
              </div>
            ) : resolvedAlarmsError ? (
              <Alert severity="error">
                Failed to load resolved alarms. Please try again later.
              </Alert>
            ) : Object.keys(resolvedAlarms).length === 0 ? (
              <Alert severity="info">No resolved alarms</Alert>
            ) : (
              <>
                {Object.keys(resolvedAlarms).map((key: any, index: any) => (
                  <Alert
                    severity="success"
                    style={{ borderRadius: 0 }}
                    key={key}
                    action={
                      isAdministrator && (
                        <IconButton
                          onClick={() => {
                            deleteLogs(resolvedAlarms[key].id, "resolved");
                          }}
                          style={{ marginLeft: "auto" }}
                          size="small"
                        >
                          <Tooltip title={"Delete Log Entry"} placement="top">
                            <DeleteIcon />
                          </Tooltip>
                        </IconButton>
                      )
                    }
                  >
                    <span
                      style={{
                        marginRight: 10,
                      }}
                    >
                      {new Date(
                        resolvedAlarms[key].CreatedTimestamp
                      ).toLocaleString()}
                    </span>
                    <span style={{ marginRight: 10 }}>
                      {new Date(
                        resolvedAlarms[key].ResetTimestamp
                      ).toLocaleString()}
                    </span>
                    {getMasterSlaveView(resolvedAlarms[key].NodeID)}
                    <span>{resolvedAlarms[key].Name}</span>
                  </Alert>
                ))}
                <Button
                  onClick={loadMoreResolvedAlarmLogs}
                  disabled={isLoadingMore || !hasMoreResolvedLogs}
                  variant="text"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  {isLoadingMore ? 'Loading...' : hasMoreResolvedLogs ? 'Load More' : 'No More Alarms'}
                </Button>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Item>
    </div>
  );
}
