import React from "react";
import useSWR from "swr";
import useSWRInfinite from 'swr/infinite'
import { API } from "aws-amplify";
import { useSearchParams } from "react-router-dom";

const activeAlarmsFetcher = (pathPart) => {
  const apiName = "ThermonovaAPI";
  const path = `/HP/alarmlog/active${pathPart}`;
  return API.get(apiName, path, {});
};

const resolvedAlarmsFetcher = ({
  key,
  pathPart
}) => {
  const apiName = "ThermonovaAPI";
  const path = `/HP/alarmlog/resolved${pathPart}`;
  return API.get(apiName, path, {});
};

function alphabetical_sort_object_of_objects(data, attr) {
  var arr = [];
  for (var prop in data) {
    if (data.hasOwnProperty(prop)) {
      var obj = {};
      obj[prop] = data[prop];
      obj["tempSortName"] = data?.[prop]?.[attr]?.toLowerCase();
      arr.push(obj);
    }
  }

  arr.sort(function (a, b) {
    var at = a.tempSortName,
      bt = b.tempSortName;
    return at < bt ? 1 : at > bt ? -1 : 0;
  });

  var result = [];
  for (var i = 0, l = arr.length; i < l; i++) {
    var obj2 = arr[i];
    delete obj2.tempSortName;
    for (var prop2 in obj2) {
      if (obj2.hasOwnProperty(prop2)) {
        var id = prop2;
      }
    }
    var item = obj2[id];
    if (item !== "ActiveError" && item !== "OldError") {
      item["id"] = id;
      result.push(item);
    }
  }
  return result;
}

const useActiveAlarms = () => {
  const [searchParams] = useSearchParams();
  const serialnumber = searchParams.get("serialnumber");
  const { data, error, isLoading } = useSWR(
    `?Serialnumber=${serialnumber}`,
    activeAlarmsFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return { 
    activeAlarms:
      alphabetical_sort_object_of_objects(
        data?.ActiveError || [],
        "CreatedTimestamp"
      ) || [],
    error,
    isLoading,
   };
};


const useResolvedAlarms = () => {
  const [searchParams] = useSearchParams();
  const serialnumber = searchParams.get("serialnumber");
  const loadingRef = React.useRef(false);

  const getKey = React.useCallback((pageIndex, previousPageData) => {
    if (pageIndex === 0) {
      return {
        key: `resolved-${serialnumber}-${pageIndex}`,
        pathPart: `?Serialnumber=${serialnumber}`
      };
    }

    // Stop fetching if we don't have more data or missing last evaluated key
    if (!previousPageData?.hasMoreResolvedLogs || !previousPageData?.ResolvedLastEvaluatedKey?.sk) {
      return null;
    }

    // Next pages
    return {
      key: `resolved-${serialnumber}-${pageIndex}`,
      pathPart: `?Serialnumber=${serialnumber}&resolvedLastEvaluatedKey=${previousPageData.ResolvedLastEvaluatedKey.sk}`
    };
  }, [serialnumber]);

  const { data, error, isLoading, size, setSize } = useSWRInfinite(
    getKey,
    resolvedAlarmsFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      dedupingInterval: 5000,
      shouldRetryOnError: false,
      persistSize: false,
      revalidateAll: false,
      parallel: false,
      revalidateFirstPage: false,
    }
  );

  const resolvedAlarms = React.useMemo(() => {
    if (!data) return [];
    const combinedErrors = data.reduce((acc, page) => ({
      ...acc,
      ...(page?.OldError || {})
    }), {});

    return alphabetical_sort_object_of_objects(
      combinedErrors,
      "CreatedTimestamp"
    );
  }, [data]);

  const loadMoreResolvedAlarmLogs = React.useCallback(() => {
    if (loadingRef.current) return;
    if (!data?.[size - 1]?.hasMoreResolvedLogs) return;
    if (isLoading) return;

    loadingRef.current = true;
    setSize(size + 1).finally(() => {
      loadingRef.current = false;
    });
  }, [data, size, setSize, isLoading]);

  React.useEffect(() => {
    return () => {
      loadingRef.current = false;
    };
  }, [serialnumber]);

  const hasMoreResolvedLogs = data?.[data.length - 1]?.hasMoreResolvedLogs || false;
  const isLoadingMore = isLoading && size > 1;

  return {
    resolvedAlarms,
    error,
    isLoading,
    isLoadingMore,
    loadMoreResolvedAlarmLogs,
    hasMoreResolvedLogs,
  };
};

export {
  useActiveAlarms,
  useResolvedAlarms,
};
